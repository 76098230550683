var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.close),expression:"close"}],staticClass:"beam-menu",class:{
    'beam-menu--green': _vm.theme === 'green',
    'beam-menu--orange': _vm.theme === 'orange'
}},[_vm._t("trigger",[_c('div',{staticClass:"beam-btn--more",class:{ 'beam-btn--more--green': _vm.theme === 'green' },on:{"click":_vm.toggleMenu}})]),_vm._v(" "),_c('div',{staticClass:"beam-menu-expanded beam-menu-expanded-vue",class:{
        'active': _vm.active,
        'beam-menu-right': _vm.right,
        'beam-menu-top': _vm.top,
        'beam-menu-center': _vm.center,
        'beam-menu-center-bottom': _vm.centerBottom
    }},[_c('ul',[_vm._t("default")],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }