<template>
    <div class="sf-color" v-on-clickaway="closePicker">
        <div class="sf-color__label"
             @click="togglePicker"
             :style="{ background: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')' }"
        ></div>
        <div class="sf-color__picker" v-show="pickerIsVisible">
            <chrome-picker :value="colors" @input="updateValue"></chrome-picker>
            <input type="hidden" :name="name" :value="selectedColor">
        </div>
    </div>
</template>

<script>
    import { Chrome } from 'vue-color'
    import { mixin as clickaway } from 'vue-clickaway'

    export default {
        mixins: [ clickaway ],
        props: [ 'name', 'rgba' ],
        data() {
            return {
                colors: {
                    hex: this.rgb2hex(`rgba(${this.rgba.r}, ${this.rgba.g}, ${this.rgba.b}, ${this.rgba.a})`),
                    a: 1,
                },
                color: this.rgba,
                selectedColor: this.rgbaToJsonString(this.rgba),
                pickerIsVisible: false,
            }
        },
        components: {
            'chrome-picker': Chrome
        },
        mounted() {
            // console.log(this.rgba);
        },
        methods: {
            togglePicker() {
                return this.pickerIsVisible = !this.pickerIsVisible
            },
            closePicker() {
                this.pickerIsVisible = false
            },
            updateValue(value) {
                this.color = value.rgba
                this.selectedColor = this.rgbaToJsonString(value.rgba)
            },
            rgbaToJsonString(rgba) {
                return `{"r": ${rgba.r}, "g": ${rgba.g}, "b": ${rgba.b}, "a": ${rgba.a}}`
            },
            rgb2hex(rgb) {
                rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
                return (rgb && rgb.length === 4) ? "#" +
                    ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
                    ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
                    ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
            },
        }
    }
</script>

<style type="scss">
    .sf-color {
        display: inline;
        position: relative;
    }

    .sf-color__label {
        width: 25px;
        height: 25px;
        border: 3px solid #FCF6F3;
        border-radius: 100%;
        box-shadow: 0 0 4px #A4ADB5;
    }

    .sf-color__picker {
        position: absolute;
        z-index: 2;
    }
</style>