<script>
    import { EventBus } from '../event-bus.js'

    export default {
        props: {
            label: null,
            submit: null,
            name: null,
            type: {
                type: String,
                default: "default",
            },
            avatar: null,
            button: null,
        },
        data() {
            return {
                fileName: null
            }
        },
        components: {
        },
        mounted() {
        },
        methods: {
            onFileChange(e) {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return
                }

                this.fileName = files[0].name;

                if (this.submit) {
                    $(`#${this.submit}`).submit()
                    EventBus.$emit('ladda-start')
                }
            },
        }
    }
</script>

<template>
    <div>
        <!-- Avatar style -->
        <template v-if="type === 'avatar'">
            <div class="upload-avatar-group">
                <input type="file" :name="name" :id="name" @change="onFileChange" accept="image/*">
                <span class="upload-avatar-label">
<!--                    <img :src="avatar" alt="">-->
                </span>

                <template v-if="button">
                    <button-component class="mt-16">{{ button }}</button-component>
                </template>
            </div>
        </template>

        <!-- Default style -->
        <template v-else>
            <div class="upload-group">
                <input type="file" :name="name" :id="name" @change="onFileChange" accept="image/*">
                <span class="upload-label">
                    <span v-if="fileName" class="upload-label-filename">{{ fileName }}</span>
                    <span v-else class="upload-label-text">{{ label }}</span><br>
                </span>
            </div>
        </template>
    </div>

</template>