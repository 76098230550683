<template>

    <div class="beam-menu" :class="{
        'beam-menu--green': theme === 'green',
        'beam-menu--orange': theme === 'orange'
    }"  v-on-clickaway="close">
        <slot name="trigger">
            <div class="beam-btn--more" :class="{ 'beam-btn--more--green': theme === 'green' }" @click="toggleMenu"></div>
        </slot>
        <div class="beam-menu-expanded beam-menu-expanded-vue" :class="{
            'active': active,
            'beam-menu-right': right,
            'beam-menu-top': top,
            'beam-menu-center': center,
            'beam-menu-center-bottom': centerBottom
        }">
            <ul>
                <slot></slot>
            </ul>
        </div>
    </div>

</template>

<script>
    import {mixin as clickaway} from 'vue-clickaway';

    export default {
        mixins: [ clickaway ],
        props: {
            direction: {
                default: 'left'
            },
            theme: {
                default: 'orange'
            },
        },
        data() {
            return {
                active: false,
                right: false,
                top: false,
                center: false,
                centerBottom: false,
            }
        },
        mounted() {
            switch (this.direction) {
                case 'right':
                    this.right = true
                    break

                case 'top':
                    this.top = true
                    break

                case 'center':
                    this.center = true
                    break

                case 'center-bottom':
                    this.centerBottom = true
                    break
            }
        },
        methods: {
            toggleMenu() {
                this.active = ! this.active
            },
            close() {
                this.active = false
            },
            isActive() {
                return this.active
            }
        },
    }
</script>
