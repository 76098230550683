<template>
    <button
        @click="onClick"
        ref="ladda"
        class="beam-btn ladda-button"
        data-style="zoom-in"
        type="submit"
        data-spinner-color="#fff"
        data-spinner-size="24"
    >
        <span class="ladda-label">
            <slot></slot>
        </span>
    </button>
</template>

<script>
    import * as Ladda from 'ladda'
    import { EventBus } from '../event-bus.js'

    export default {
        props: [ ],
        data() {
            return {
                ladda: null,
            }
        },
        components: {
        },
        mounted() {
            this.ladda = Ladda.create(this.$refs.ladda)

            // Stop the ladda spinner when a user clicks the browser back button
            window.addEventListener('pageshow', (event) => {
                let historyTraversal =
                    event.persisted ||
                    (typeof window.performance != "undefined" && window.performance.navigation.type === 2)

                if (historyTraversal) {
                    this.ladda.stop()
                }
            })

            EventBus.$on('ladda-start', () => {
                this.ladda.start()
            })
        },
        methods: {
            onClick() {
                this.ladda.start()

                $('form').submit()
            }
        },
        beforeDestroy: function() {
            this.ladda.remove();
            delete this.ladda;
        }
    }
</script>