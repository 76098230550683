<template>
    <div>

        <div class="beam-input-group">
            <label class="beam-checkbox" style="line-height: 1.7em">
                <input type="checkbox" value="1" v-model="viaAgency">
                <span class="text-sm">{{ labelRadio }}</span>
            </label>
        </div>

        <div class="beam-input-group" v-show="viaAgency">
            <div class="beam-input-group">
                <input type="text" class="beam-input" name="agency" v-model="agencyName">
                <span class="beam-input-bar"></span>
                <label class="beam-label">{{ labelInput }}</label>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            labelRadio: null,
            labelInput: null,
        },
        data() {
            return {
                viaAgency: false,
                agencyName: null,
            }
        },
        watch: {
            viaAgency: function(val) {
                if (! val) {
                    this.agencyName = null
                }
            }
        },
    }
</script>